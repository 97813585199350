import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsPage = () => (
  <Layout>
    <Seo title="Terms Of Service | Clove" />
    <div className={"relative bg-white py-16"}>
      <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl text-content"}>
        <h1 className="heading-primary">Terms of Service</h1>

        <p>
          <strong>Last updated: June 26, 2020</strong>
        </p>

        <p>
          From everyone at Clove, thank you for using our products! We build
          them to help you do your best work. Because we don't know every one of
          our customers personally, we have to put in place some Terms of
          Service to help keep the ship afloat.
        </p>
        <p>
          When we say “Company”, “we”, “our”, or “us” in this document, we are
          referring to Clove.{" "}
        </p>
        <p>
          When we say “Services”, we mean any product created and maintained by
          Clove. That includes Clove (all versions) and Customer Hub, whether
          delivered within a web browser, desktop application, mobile application, or another format.
        </p>
        <p>We may update these Terms of Service in the future. </p>
        <p>
          When you use our Services, now or in the future, you are agreeing to
          the latest Terms of Service. That's true for any of our existing and
          future products and all features that we add to our Services over
          time. There may be times where we do not exercise or enforce any right
          or provision of the Terms of Service; in doing so, we are not waiving
          that right or provision. These terms do contain a limitation of our
          liability.
        </p>
        <p>
          If you violate of any of the terms, we may terminate your account.
          That's a broad statement and it means you need to place a lot of trust
          in us. We do our best to deserve that trust by being open about who we
          are, how we work, and keeping an open door to{" "}
          <a href="mailto:hello@clovecx.com">your feedback.</a>
        </p>

        <h3>Account Terms</h3>
        <p>
          1. You are responsible for maintaining the security of your account
          and password. The Company cannot and will not be liable for any loss
          or damage from your failure to comply with this security obligation.{" "}
        </p>
        <p>
          2. You may not use the Services for any purpose outlined in our{" "}
          <a href="https://www.clovecx.com/restrictions/">
            Use Restrictions Policy
          </a>
          .
        </p>
        <p>
          3. You are responsible for all content posted and activity that occurs
          under your account. That includes content posted by others who either:
          (a) have access to your login credentials; or (b) have their own
          logins under your account.
        </p>
        <p>
          4. You must be a human. Accounts registered by “bots” or other
          automated methods are not permitted.
        </p>

        <h3>Refunds, Plan Changes, Cancellation, and Termination</h3>
        <p>
          1. All changes to your account plans and payments can be made by
          contacting <a href="mailto:hello@clovecx.com">hello@clovecx.com</a>.
        </p>
        <p>
          2. When your account is cancelled, all of your content will be
          inaccessible from the Services immediately upon cancellation. Within
          30 days, we reserve the right to permanently delete all content from
          active systems and logs. We cannot recover this information once it
          has been permanently deleted.
        </p>
        <p>
          3. If you cancel the Service before the end of your current paid up
          month, your cancellation will take effect immediately, and you will
          not be charged again. We do not automatically prorate unused time in
          the last billing cycle.
        </p>
        <p>
          3. We have the right to suspend or terminate your account and refuse
          any and all current or future use of our Services for any reason at
          any time. Suspension means you and any other users on your account
          will not be able to access the account or any content in the account.
          Termination will furthermore result in the deletion of your account or
          your access to your account, and the forfeiture and relinquishment of
          all content in your account. We also reserve the right to refuse the
          use of the Services to anyone for any reason at any time. There are
          some things we staunchly stand against and this clause is how we
          exercise that stance. For more details, see our Use Restrictions
          policy.
        </p>
        <p>
          4. Verbal, physical, written or other abuse (including threats of
          abuse or retribution) of Company employee or officer will result in
          immediate account termination.
        </p>

        <h3>Modifications to the Service and Prices</h3>
        <p>
          1. We reserve the right at any time to modify or discontinue,
          temporarily or permanently, any part of our Services with or without
          notice.
        </p>
        <p>
          2. Sometimes we change the pricing structure for our products. When we
          do that, we tend to exempt existing customers from those changes.
          However, we may choose to change the prices for existing customers. If
          we do so, we will give at least 30 days notice and will notify you via
          the email address on record. We may also post a notice about changes
          on our websites or the affected Services themselves.
        </p>

        <h3>Uptime, Security, and Privacy</h3>
        <p>
          1. Your use of the Services is at your sole risk. We provide these
          Services on an “as is” and “as available” basis. We do not offer
          service-level agreements for most of our Services but do take uptime
          of our applications seriously.{" "}
        </p>
        <p>
          2. We reserve the right to temporarily disable your account if your
          usage significantly exceeds the average usage of other customers of
          the Services. Of course, we'll reach out to the account owner before
          taking any action except in rare cases where the level of use may
          negatively impact the performance of the Service for other customers.
        </p>
        <p>
          3. We take many measures to protect and secure your data through
          backups, redundancies, and encryption. We enforce encryption for data
          transmission from the public Internet. There are some edge cases where
          we may send your data through our network unencrypted. Please refer to
          our <a href="https://www.clovecx.com/security/">Security Overview</a>{" "}
          for full details.
        </p>
        <p>
          4. When you use our Services, you entrust us with your data. We take
          that trust to heart. You agree that Clove may process your data as
          described in our{" "}
          <a href="https://www.clovecx.com/privacy/">Privacy Policy</a> and for
          no other purpose. We as humans can access your data for the following
          reasons:
        </p>
        <ul>
          <li>
            To help you with support requests you make and improve the product.
          </li>
          <li>
            To safeguard Clove. We'll look at logs and metadata as part of our
            work to ensure the security of your data and the Services as a
            whole. If necessary, we may also access accounts as part of an abuse
            report investigation.
          </li>
          <li>To the extent required by applicable law.</li>
        </ul>
        <p></p>
        <p>
          5. We use third party vendors and hosting partners to provide the
          necessary hardware, software, networking, storage, and related
          technology required to run the Services.{" "}
        </p>
        <p>
          6. Under the California Consumer Privacy Act (“CCPA”), Clove is a
          “service provider”, not a “business” or “third party”, with respect to
          your use of the Services. That means we process any data you share
          with us only for the purpose you signed up for and as described in
          these Terms of Service and our Privacy policy. We do not retain, use,
          disclose, or sell any of that information for any other commercial
          purposes unless we have your explicit permission. And on the
          flip-side, you agree to comply with your requirements under the CCPA
          and not use Clove’s Services in a way that violates the regulations.
        </p>

        <h3>Copyright and Content Ownership</h3>
        <p>
          1. All content posted on the Services must comply with U.S. copyright
          law.{" "}
        </p>
        <p>
          2. We claim no intellectual property rights over the material you
          provide to the Services. All materials uploaded remain yours.
        </p>
        <p>
          3. We do not pre-screen content, but reserve the right (but not the
          obligation) in our sole discretion to refuse or remove any content
          that is available via the Service.
        </p>
        <p>
          4. The names, look, and feel of the Services are copyright© to the
          Company. All rights reserved. You may not duplicate, copy, or reuse
          any portion of the HTML, CSS, JavaScript, or visual design elements
          without express written permission from the Company. You must request
          permission to use the Company's logo or any Service logos for
          promotional purposes. Please{" "}
          <a href="mailto:hello@clovecx.com">email us</a> requests to use logos.
          We reserve the right to rescind this permission if you violate these
          Terms of Service.
        </p>
        <p>
          5. You agree not to reproduce, duplicate, copy, sell, resell or
          exploit any portion of the Services, use of the Services, or access to
          the Services without the express written permission by the Company.
        </p>
        <p>
          6. You must not modify another website so as to falsely imply that it
          is associated with the Services or the Company.
        </p>

        <h3>Features and Bugs</h3>
        <p>
          We design our Services with care, based on our own experience and the
          experiences of customers who share their time and feedback. However,
          there is no such thing as a service that pleases everybody. We make no
          guarantees that our Services will meet your specific requirements or
          expectations.
        </p>
        <p>
          We also test all of our features before shipping them. As with any
          software, our Services inevitably have some bugs. We track the bugs
          reported to us and work through priority ones, especially any related
          to security or privacy. Not all reported bugs will get fixed and we
          don't guarantee completely error-free Services.
        </p>

        <h3>Liability</h3>
        <p>
          We mention liability throughout these Terms but to put it all in one
          section:
        </p>
        <p>
          <strong>
            You expressly understand and agree that the Company shall not be
            liable, in law or in equity, to you or to any third party for any
            direct, indirect, incidental, lost profits, special, consequential,
            punitive or exemplary damages, including, but not limited to,
            damages for loss of profits, goodwill, use, data or other intangible
            losses (even if the Company has been advised of the possibility of
            such damages), resulting from: (i) the use or the inability to use
            the Services; (ii) the cost of procurement of substitute goods and
            services resulting from any goods, data, information or services
            purchased or obtained or messages received or transactions entered
            into through or from the Services; (iii) unauthorized access to or
            alteration of your transmissions or data; (iv) statements or conduct
            of any third party on the service; (v) or any other matter relating
            to this Terms of Service or the Services, whether as a breach of
            contract, tort (including negligence whether active or passive), or
            any other theory of liability.
          </strong>
        </p>
        <p>
          In other words: choosing to use our Services does mean you are making
          a bet on us. If the bet does not work out, that's on you, not us. We
          do our darnedest to be as safe a bet as possible through careful
          management of the business; investments in security, infrastructure,
          and talent; and in general{" "}
          <a href="https://www.clovecx.com/about/">giving a damn</a>. If you
          choose to use our Services, thank you for betting on us.
        </p>
        <p>
          If you have a question about any of the Terms of Service, please{" "}
          <a href="mailto:hello@clovecx.com">contact us</a>.
        </p>

        <p>
          Adapted from the{" "}
          <a href="https://github.com/basecamp/policies">
            Basecamp open-source policies
          </a>{" "}
          / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsPage
